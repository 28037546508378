import {
  getTypeListSpeakAndRead,
  getTypeListWriteAndListen,
  ratePercent,
  h1List69up,
  h1List62to68,
  h1List50to61,
  h1List35down,
  h1List36to49
} from '@/utils/type-list'
import { apiGreType } from '@/api/api.js'
import { apiUserHomeStat } from '@/api/api'

export default {
  data() {
    return {
      typeListSpeakAndRead: getTypeListSpeakAndRead(),
      typeListWriteAndListen: getTypeListWriteAndListen(),
      typeObject: {},
      ratePercentModel: ratePercent,
      mustExc: h1List69up,
      aiTypeList: [
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        42,
        43,
        44,
        52,
        53
      ],
      userStatInfo: {},
      dropColumnName: '79分及以上'
    }
  },
  created() {
    this.fetchList()
    this.fetchUserStat()
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    fetchList() {
      apiGreType().then((res) => {
        if (res.code != 200) {
          return
        }
        let obj = {}
        res.data.forEach((item) => {
          item.cate_sub.forEach((sub) => {
            const { done_cnt, total_cnt } = sub
            obj[sub.id] = (done_cnt / total_cnt) * 100
          })
        })
        this.typeObject = obj
      })
    },
    fetchUserStat() {
      apiUserHomeStat().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.userStatInfo = res.data

        switch (true) {
          case this.userStatInfo.goal_score >= 79:
            this.handleCommand('1')
            break
          case this.userStatInfo.goal_score >= 69 &&
            this.userStatInfo.goal_score <= 78:
            this.handleCommand('2')
            break
          case this.userStatInfo.goal_score >= 62 &&
            this.userStatInfo.goal_score <= 68:
            this.handleCommand('3')
            break
          case this.userStatInfo.goal_score >= 50 &&
            this.userStatInfo.goal_score <= 61:
            this.handleCommand('4')
            break
          case this.userStatInfo.goal_score >= 36 &&
            this.userStatInfo.goal_score <= 49:
            this.handleCommand('5')
            break
          case this.userStatInfo.goal_score <= 35:
            this.handleCommand('6')
            break
          default:
            break
        }
      })
    },
    handleToDetail(item) {
      sessionStorage.setItem('clid', item.clid)
      sessionStorage.setItem('type', item.type)
      this.$router.push({
        name: 'Exercises',
        query: {
          clid: item.clid
        }
      })
    },

    handleCommand(command) {
      switch (command) {
        case '1':
          this.mustExc = h1List69up
          this.dropColumnName = this.i18n.Pra.topPoints
          break
        case '2':
          this.mustExc = h1List69up
          this.dropColumnName = `69-78${this.i18n.Pra.points}`
          break
        case '3':
          this.mustExc = h1List62to68
          this.dropColumnName = `62-68${this.i18n.Pra.points}`
          break
        case '4':
          this.mustExc = h1List50to61
          this.dropColumnName = `50-61${this.i18n.Pra.points}`
          break
        case '5':
          this.mustExc = h1List36to49
          this.dropColumnName = `36-49${this.i18n.Pra.points}`
          break
        case '6':
          this.mustExc = h1List35down
          this.dropColumnName = this.i18n.Pra.below
          break
        default:
          break
      }
    }
  }
}
